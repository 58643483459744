// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-favoriten-js": () => import("./../../../src/pages/favoriten.js" /* webpackChunkName: "component---src-pages-favoriten-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-init-js": () => import("./../../../src/pages/init.js" /* webpackChunkName: "component---src-pages-init-js" */),
  "component---src-pages-pflege-spielen-fragen-js": () => import("./../../../src/pages/pflege/spielen/fragen.js" /* webpackChunkName: "component---src-pages-pflege-spielen-fragen-js" */),
  "component---src-pages-pflege-spielen-gratulation-js": () => import("./../../../src/pages/pflege/spielen/gratulation.js" /* webpackChunkName: "component---src-pages-pflege-spielen-gratulation-js" */),
  "component---src-pages-pflege-spielen-js": () => import("./../../../src/pages/pflege/spielen.js" /* webpackChunkName: "component---src-pages-pflege-spielen-js" */),
  "component---src-pages-pflege-testergebnis-js": () => import("./../../../src/pages/pflege/testergebnis.js" /* webpackChunkName: "component---src-pages-pflege-testergebnis-js" */),
  "component---src-templates-area-mfa-js": () => import("./../../../src/templates/areaMfa.js" /* webpackChunkName: "component---src-templates-area-mfa-js" */),
  "component---src-templates-area-mtla-js": () => import("./../../../src/templates/areaMtla.js" /* webpackChunkName: "component---src-templates-area-mtla-js" */),
  "component---src-templates-area-mtra-js": () => import("./../../../src/templates/areaMtra.js" /* webpackChunkName: "component---src-templates-area-mtra-js" */),
  "component---src-templates-blogs-js": () => import("./../../../src/templates/blogs.js" /* webpackChunkName: "component---src-templates-blogs-js" */),
  "component---src-templates-care-js": () => import("./../../../src/templates/care.js" /* webpackChunkName: "component---src-templates-care-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-landingpage-js": () => import("./../../../src/templates/landingpage.js" /* webpackChunkName: "component---src-templates-landingpage-js" */),
  "component---src-templates-reference-js": () => import("./../../../src/templates/reference.js" /* webpackChunkName: "component---src-templates-reference-js" */),
  "component---src-templates-station-js": () => import("./../../../src/templates/station.js" /* webpackChunkName: "component---src-templates-station-js" */),
  "component---src-templates-stations-js": () => import("./../../../src/templates/stations.js" /* webpackChunkName: "component---src-templates-stations-js" */)
}

